import { useMemo, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Button,
} from "@chakra-ui/react";

import { FAQ_VIEW_MORE } from "@constants/analytics";

import { StrapiSectionDetail } from "@customtypes/global";

import { trackEvent } from "dapp-sdk-v2";

import Section from "../Section";

interface FAQProps {
  sectionDetails: StrapiSectionDetail;
  faqs: Array<{
    question: string;
    answer: any;
  }>;
}

const FAQs = ({ sectionDetails, faqs }: FAQProps) => {
  const [isViewMoreSelected, setIsViewMoreSelected] = useState(false);

  const updatedFAQs = useMemo(() => {
    return isViewMoreSelected ? faqs : faqs.slice(0, 3);
  }, [faqs, isViewMoreSelected]);

  const getMT = (index: number, isViewMoreSelected: boolean) => {
    if (index === 0) return 0;
    if (isViewMoreSelected) return "1rem";
    if (!isViewMoreSelected && index < 3) return "1rem";
    return 0;
  };

  return (
    <Section
      isH2
      title={sectionDetails.title}
      subTitle={sectionDetails.sub_title}
      id={sectionDetails.section_id}
    >
      <Accordion
        allowToggle
        w="100%"
        maxW="786px"
        mt={{ base: "24px", lg: "40px" }}
      >
        {faqs.map((item, index: number) => (
          <AccordionItem
            h={isViewMoreSelected ? "auto" : index < 3 ? "auto" : 0}
            overflow="hidden"
            border="0px"
            bg="backgroundPrimary"
            mt={getMT(index, isViewMoreSelected)}
            key={item.question}
            borderRadius={{ base: "4px", lg: "8px" }}
            textAlign="left"
            as="h3"
          >
            <AccordionButton
              p={{ base: "12px 1rem", lg: "1.5rem 2rem" }}
              _expanded={{
                pb: { base: "8px", lg: "12px" },
              }}
            >
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight="600"
                fontSize={{ base: "14px", lg: "16px" }}
                lineHeight={{ base: "20px", lg: "24px" }}
                color="textPrimary"
              >
                {item.question}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel
              pb={{ base: "12px", lg: "24px" }}
              pl={{ base: "16px", lg: "32px" }}
              pr={{ base: "44px", lg: "68px" }}
            >
              <div>
                <Box
                  color="#3F6870 !important"
                  dangerouslySetInnerHTML={{ __html: item.answer }}
                  fontWeight="500"
                  fontSize={{ base: "10px", lg: "12px" }}
                  lineHeight={{ base: "14px", lg: "18px" }}
                  sx={{
                    "& ol": {
                      pl: { base: 2, lg: 4 },
                    },
                    "& ul": {
                      pl: { base: 2, lg: 4 },
                    },
                  }}
                  className="faqAnswer"
                />
              </div>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      {updatedFAQs.length !== faqs.length ? (
        <Button
          mt={{ base: "1.5rem", lg: "2.5rem" }}
          variant="outline"
          size="xl"
          w={{ base: "157px", lg: "201px" }}
          h={{ base: "40px", lg: "58px" }}
          borderColor="seperator"
          _hover={{
            color: "white",
            bg: "primary",
          }}
          onClick={() => {
            trackEvent(FAQ_VIEW_MORE);
            setIsViewMoreSelected(!isViewMoreSelected);
          }}
          fontSize={{ base: "14px", lg: "16px" }}
          lineHeight={{ base: "20px", lg: "24px" }}
          fontWeight="600"
        >
          {sectionDetails.cta_text}
        </Button>
      ) : null}
    </Section>
  );
};

export default FAQs;
